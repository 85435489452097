import React from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

// import brandImage from "@/images/client-logo.png";
import brandImage from "@/images/client1.jpg";

import client1 from "@/images/client1.jpg";
import Img from "./img";

const SponsorOne = ({ title, logos, extraClass }) => {
  const sponsorCarouselOptions = {
    spaceBetween: 100,
    slidesPerView: 5,
    autoplay: { delay: 5000 },
    breakpoints: {
      0: {
        spaceBetween: 30,
        slidesPerView: 2
      },
      375: {
        spaceBetween: 30,
        slidesPerView: 2
      },
      575: {
        spaceBetween: 30,
        slidesPerView: 3
      },
      767: {
        spaceBetween: 50,
        slidesPerView: 4
      },
      991: {
        spaceBetween: 50,
        slidesPerView: 5
      },
      1199: {
        spaceBetween: 100,
        slidesPerView: 5
      }
    }
  };
  return (
    <div
      className={`clients_logo_area text-center  ${extraClass}`}
      style={{
        padding: '60px 0px',
      }}
    >
      <Container>
        <h2 style={{ marginBottom: '30px' }}>{title}</h2>
        <Swiper {...sponsorCarouselOptions} className="clients_logo ">
          <div className="swiper-wrapper">
            {logos.map(i => <SwiperSlide><Img src={i} alt="" /></SwiperSlide>)}
          </div>
        </Swiper>
      </Container>
    </div>
  );
};

export default SponsorOne;
